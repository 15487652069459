import type { SearchHistoryItem, SearchByQueryResponse } from '~/types/NewTypes/Search';

export const useSearchStore = defineStore('search', {
  state: () => ({
    searchQuery: '' as string,
    searchHistory: [] as SearchHistoryItem[],
    controller: null as AbortController | null,
  }),

  actions: {
    async searchByQuery(recaptchaToken?: string) {
      if (this.controller) {
        this.controller.abort();
      }
      this.controller = new AbortController();
      const roistatVisitId = useCookie('roistat_visit');

      try {
        const { data } = await useCustomFetch<SearchByQueryResponse>(
          '/multisearch',
          {
            signal: this.controller?.signal,
            query: {
              'filter[search]': this.searchQuery.trim(),
              recaptchaToken,
              roistat: roistatVisitId.value,
            },
          },
          '2',
        );

        if (data.value) {
          return data.value;
        } else {
          return { offers: [], brands: [], categories: [], seoCatalogPages: [] };
        }
      } catch (e) {
        if (e && process.env.NODE_ENV !== 'production') {
          console.error(e);
        }
        return { offers: [], brands: [], categories: [], seoCatalogPages: [] };
      }
    },

    getSearchHistory() {
      const historyItems = localStorage.getItem('search-history');

      if (historyItems) this.searchHistory = JSON.parse(historyItems);
    },

    setSearchHistory(data: SearchHistoryItem) {
      const historyItems = [...this.searchHistory, data];
      const savedUrls: string[] = this.searchHistory.map((item) => item.url);

      historyItems.forEach((item: SearchHistoryItem) => {
        const { url } = item;

        if (!savedUrls.includes(url)) {
          savedUrls.push(url);
          this.searchHistory.push(item);
        }
      });

      if (this.searchHistory.length > 5) this.searchHistory = this.searchHistory.slice(1, 6);

      localStorage.setItem('search-history', JSON.stringify(this.searchHistory));
    },

    removeFromSearchHistory(item: SearchHistoryItem) {
      const historyItems = localStorage.getItem('search-history');

      if (historyItems) {
        this.searchHistory = JSON.parse(historyItems).filter((i: SearchHistoryItem) => i.url !== item.url);
        localStorage.setItem('search-history', JSON.stringify(this.searchHistory));
      }
    },
  },
});
