<script lang="ts" setup>
import type { MobileMenuItem } from '~/types/SiteSettings';

const mobileMenu = ref<MobileMenuItem[]>([]);

const siteStore = useSiteStore();
mobileMenu.value = await siteStore.getMobileMenu();

const { isShowAboveHeaderBlock } = storeToRefs(siteStore);
const expandedItems = ref<number[]>([]);
const route = useRoute();

watch(
  () => route.name,
  () => (siteStore.isMobileMenuOpen = false),
);

function isExpanded(item: MobileMenuItem) {
  return expandedItems.value.includes(item.id);
}

function toggleItem(item: MobileMenuItem) {
  if (isExpanded(item)) {
    expandedItems.value = expandedItems.value.filter((i) => i !== item.id);
  } else {
    expandedItems.value.push(item.id);
  }
}

function checkForExpandable(item: MobileMenuItem) {
  return item.children && item.children.length;
}
</script>

<template>
  <nav
    :class="{ 'with-top-bar': isShowAboveHeaderBlock }"
    class="mobile-menu"
  >
    <template
      v-for="item in mobileMenu"
      :key="item.id"
    >
      <button
        v-if="checkForExpandable(item)"
        class="mobile-menu__item"
        name="expandable mobile menu item"
        @click="toggleItem(item)"
      >
        <div class="flex items-center gap-2">
          <svg
            v-if="item.data?.icon"
            class="w-7 h-6"
            v-html="item.data?.icon"
          />

          {{ item.name }}
        </div>

        <SvgoBack
          :class="{ 'rotate-90': isExpanded(item) }"
          class="text-xs transition-transform -rotate-90"
          filled
        />
      </button>

      <AppLink
        v-else
        :to="makeUrlRelative(item.value)"
        class="mobile-menu__item"
        @click="siteStore.toggleMobileMenu"
      >
        <div class="flex items-center gap-2">
          <svg
            v-if="item.data?.icon"
            class="w-7 h-6"
            v-html="item.data?.icon"
          />

          {{ item.name }}
        </div>
      </AppLink>

      <template v-if="isExpanded(item)">
        <AppLink
          v-for="child in item.children"
          :key="child.id"
          :to="makeUrlRelative(child.value)"
          class="mobile-menu__item mobile-menu__item--child"
          @click="siteStore.toggleMobileMenu"
        >
          <div class="flex items-center gap-2">
            <svg
              v-if="child.data?.icon"
              class="w-7 h-6"
              v-html="child.data?.icon"
            />

            {{ child.name }}
          </div>
        </AppLink>
      </template>
    </template>
  </nav>
</template>

<style lang="scss" scoped>
.mobile-menu {
  z-index: 100;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: calc(100dvh - 123px);
  background-color: $white;
  font-size: $text-sm;

  &.with-top-bar {
    height: calc(100dvh - 151px);
  }

  @include screen-md {
    height: calc(100dvh - 58px);

    &.with-top-bar {
      height: calc(100dvh - 86px);
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid $hell-black-10;
    font-weight: $bold;

    &--child {
      font-weight: $regular;
    }
  }
}
</style>
