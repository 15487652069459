<script lang="ts" setup></script>

<template>
  <a
    class="yandex-rating-widget"
    href="https://yandex.ru/maps/org/hellride/62706069996/?ll=37.542572%2C55.786414&z=17"
    target="_blank"
  >
    <div class="flex items-center gap-0.5">
      <SvgoSocialYandex
        :font-controlled="false"
        class="size-6 text-[#FC401D] mr-1"
        filled
      />

      <div class="font-bold text-sm hover:text-hell-black-80">5,0</div>

      <SvgoStar
        v-for="i in 5"
        :key="i"
        :font-controlled="false"
        class="size-4 text-[#ffcc00]"
        filled
      />
    </div>

    <div class="text-hell-black-80 text-xs">Рейтинг магазина</div>
  </a>
</template>

<style lang="scss" scoped>
.yandex-rating-widget {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 6px;
  border: 1px solid #ffcc00;
  border-radius: 16px;
  background-color: $white;
}
</style>
