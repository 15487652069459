// узнаем ширину скроллбара
function checkScrollbarWidth() {
  if (import.meta.client) {
    const div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';

    document.body.appendChild(div);
    const scrollbarWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);
    document.documentElement.style.setProperty('--scrollbar-width', scrollbarWidth ? '11px' : '0px');
  }
}

export default checkScrollbarWidth;
