<script lang="ts" setup>
import dayjs from 'dayjs';
import { POSITION, useToast } from 'vue-toastification';
import { CATALOG_PAGE_TYPES } from '~/constants/catalogPageTypes';
import type { AdminPanelResponse } from '~/types/SiteSettings';
import ToastComponent from '~/components/ui/ToastComponent.vue';

const { currentPageType, selectedOffer } = storeToRefs(useCatalogStore());
const siteStore = useSiteStore();
const isAdminPanelOpen = ref(false);
const route = useRoute();

const toggleAdminPanel = () => {
  isAdminPanelOpen.value = !isAdminPanelOpen.value;
  localStorage.setItem('is-admin-panel–open', JSON.stringify(isAdminPanelOpen.value));
};

onMounted(() => {
  const savedAdminPanelState = localStorage.getItem('is-admin-panel–open');

  if (savedAdminPanelState) {
    isAdminPanelOpen.value = JSON.parse(savedAdminPanelState);
  }
});

const isLinksDropdownOpen = ref(false);

const toggleLinksDropdown = () => {
  isLinksDropdownOpen.value = !isLinksDropdownOpen.value;
};

const adminPanelData = ref<AdminPanelResponse | null>(null);

await nextTick();

adminPanelData.value = await siteStore.getAdminPanelData();

const { text, copy, copied, isSupported } = useClipboard();
const toast = useToast();

watch(
  () => copied.value,
  () => {
    if (copied.value)
      toast.info(
        { component: ToastComponent, props: { text: 'Скопировано: ' + text.value } },
        { timeout: 2000, position: POSITION.TOP_RIGHT },
      );
  },
);
</script>

<template>
  <div
    v-if="adminPanelData"
    :class="{ 'admin-panel--open': isAdminPanelOpen }"
    class="admin-panel"
  >
    <div class="flex items-center flex-shrink-0 gap-2 *:p-1">
      <button @click="toggleAdminPanel">
        <SvgoNovaLogo
          :font-controlled="false"
          class="w-9 h-auto flex-shrink-0 text-label-blue hover:text-label-violet transition duration-200"
          filled
        />
      </button>

      <button
        :class="{ 'bg-prototype-dark-gray text-white': isLinksDropdownOpen }"
        class="relative"
        @click="toggleLinksDropdown"
        @mouseenter="isLinksDropdownOpen = true"
        @mouseleave="isLinksDropdownOpen = false"
      >
        Ссылки
        <div
          v-show="isLinksDropdownOpen"
          class="absolute top-[22px] -left-2 flex flex-col text-start gap-3 w-40 p-2 bg-label-red text-hell-black"
        >
          <a
            v-for="link in adminPanelData?.dropdown_links"
            :href="link.url"
            target="_blank"
          >
            {{ link.title }}
          </a>
        </div>
      </button>

      <a
        v-for="link in adminPanelData?.links"
        :href="link.url"
        target="_blank"
      >
        {{ link.title }}
      </a>

      <template v-if="currentPageType === CATALOG_PAGE_TYPES.OFFER_PAGE && route.name === 'catalog-slug'">
        <a
          :href="`/_admin/resources/product-nova-resources/${selectedOffer?.product_id}`"
          target="_blank"
        >
          Просмотр
        </a>

        <a
          :href="`/_admin/resources/product-nova-resources/${selectedOffer?.product_id}/edit`"
          target="_blank"
        >
          Редактирование
        </a>
      </template>
    </div>

    <div
      v-if="adminPanelData"
      class="admin-panel__dev-info *:h-full *:flex *:items-center *:text-nowrap *:gap-1"
    >
      <div class="bg-white text-[#FF2D21] px-2">laravel:</div>

      <div>
        <button
          v-if="isSupported"
          @click="copy(adminPanelData.branch)"
        >
          <SvgoCopy
            :font-controlled="false"
            class="copy-icon"
            filled
          />
        </button>
        {{ adminPanelData.branch }}
      </div>

      <div>
        <button
          v-if="isSupported"
          @click="copy(adminPanelData.commit)"
        >
          <SvgoCopy
            :font-controlled="false"
            class="copy-icon"
            filled
          />
        </button>
        {{ adminPanelData.commit }}
      </div>

      <div class="text-nowrap">{{ dayjs(adminPanelData.date).format('DD.MM.YYYY HH:mm') }}</div>

      <div class="bg-[#06DC82] text-[#020420] px-2">nuxt:</div>

      <div>
        <button
          v-if="isSupported"
          @click="copy(adminPanelData.nuxt_branch)"
        >
          <SvgoCopy
            :font-controlled="false"
            class="copy-icon"
            filled
          />
        </button>
        {{ adminPanelData.nuxt_branch }}
      </div>

      <div>
        <button
          v-if="isSupported"
          @click="copy(adminPanelData.nuxt_commit)"
        >
          <SvgoCopy
            :font-controlled="false"
            class="copy-icon"
            filled
          />
        </button>
        {{ adminPanelData.nuxt_commit }}
      </div>

      <div class="text-nowrap">{{ dayjs(adminPanelData.nuxt_date).format('DD.MM.YYYY HH:mm') }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.admin-panel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 44px;
  height: 28px;
  background-color: $label-red;
  font-size: $text-sm;

  &--open {
    overflow: visible;
    width: 100%;
  }

  &__dev-info {
    display: flex;
    flex-shrink: 1;
    gap: 8px;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;
    margin-right: 8px;
    scrollbar-width: none;
  }
}

.copy-icon {
  width: 16px;
  height: 16px;
  color: $hell-black;
  transition: color 0.3s;

  &:hover {
    color: $hell-black-60;
  }
}
</style>
