<script lang="ts" setup>
const siteStore = useSiteStore();
const cartStore = useCartStore();
const authStore = useAuthStore();
const route = useRoute();

const { cart } = storeToRefs(cartStore);

const cartBadge = computed(() => {
  let itemsInCart = 0;

  cart.value?.items.forEach((item) => {
    if (item?.count) itemsInCart += item.count;
  });

  return itemsInCart;
});

onMounted(async () => {
  if (!siteStore.aboveHeaderBlock.isActive) return;

  await nextTick();

  if (!sessionStorage.getItem('oldSiteLinkShow')) {
    sessionStorage.setItem('oldSiteLinkShow', 'true');
    siteStore.isShowAboveHeaderBlock = true;
  } else {
    const storedState = sessionStorage.getItem('oldSiteLinkShow');
    if (storedState) {
      siteStore.isShowAboveHeaderBlock = JSON.parse(storedState);
    }
  }
});

const { isMobile } = useBreakpoints();
const isSearchOpen = ref(false);
</script>

<template>
  <header class="header__container">
    <LineAboveHeader v-show="siteStore.isShowAboveHeaderBlock" />

    <div class="header__wrapper">
      <div
        :class="{ '!gap-0 !p-0': isMobile && isSearchOpen }"
        class="header__content"
      >
        <BurgerBtn
          :class="{ collapsed: isMobile && isSearchOpen }"
          :is-open="siteStore.isMobileMenuOpen"
          :title="siteStore.isMobileMenuOpen ? 'Закрыть меню' : 'Открыть меню'"
          class="header__burger-btn"
          @click="siteStore.toggleMobileMenu"
        />

        <AppLink
          :class="{ collapsed: isMobile && isSearchOpen }"
          class="header__logo"
          to="/"
        >
          <SvgoLogo
            :font-controlled="false"
            class="h-3 sm:h-4 lg:h-5"
          />
        </AppLink>

        <SearchBlock
          v-model:is-search-open="isSearchOpen"
          class="header__search-block"
        />

        <PhoneButton
          :class="{ collapsed: isMobile && isSearchOpen }"
          class="header__phone-button"
        />

        <ContactsBlock class="header__contacts-block" />

        <div class="header__actions">
          <MenuActionBtn
            :counter-badge="cartBadge"
            :is-active="route.path === '/cart'"
            label="Корзина"
            top-icon="Cart"
            url="/cart"
          />

          <MenuActionBtn
            :is-active="route.path === '/favorites'"
            label="Избранное"
            top-icon="Heart"
            url="/favorites"
          />

          <MenuActionBtn
            :is-active="authStore.isLogged ? String(route.path).includes('/profile') : route.path === '/login'"
            :label="authStore.isLogged ? 'Профиль' : 'Войти'"
            :top-icon="authStore.isLogged ? 'Profile' : 'Login'"
            :url="authStore.isLogged ? '/profile' : '/login'"
          />
        </div>

        <DesktopMenu class="header__desktop-menu" />

        <MobileMenu
          :class="{ 'header__mobile-menu--open': siteStore.isMobileMenuOpen }"
          class="header__mobile-menu"
        />
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: flex;
    flex-direction: column;
    width: 100vw;
  }

  &__wrapper {
    @include inner-wrapper;

    position: relative;
    border-bottom: 1px solid $hell-black-20;
    background-color: $white;
  }

  &__content {
    @include inner-content;

    flex-direction: row;
    gap: 8px 12px;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;

    @include screen-sm {
      gap: 8px 20px;
    }

    @include screen-md {
      padding: 4px 12px;
    }

    @include screen-lg {
      display: grid;
      grid-template-areas:
        'logo search contacts actions'
        'desktop-menu desktop-menu desktop-menu desktop-menu';
      grid-template-rows: auto auto;
      grid-template-columns: auto 1fr auto auto;
      padding: 8px 24px 0;
    }
  }

  &__burger-btn {
    display: block;
    flex-shrink: 0;
    overflow: hidden;
    width: 24px;
    transition: all 0.3s;

    @include screen-lg {
      display: none;
    }
  }

  &__logo {
    flex-shrink: 0;
    grid-area: logo;
    justify-self: center;
    overflow: hidden;
    width: 78px;
    transition: all 0.3s;

    &:hover {
      color: $hell-black-80;
    }

    @include screen-sm {
      justify-self: flex-start;
      width: unset;
    }
  }

  &__search-block {
    position: relative;
    grid-area: search;
    height: 48px;
  }

  &__phone-button {
    display: block;
    grid-area: phone-button;

    @include screen-lg {
      display: none;
    }
  }

  &__contacts-block {
    display: none;
    grid-area: contacts;
    justify-self: end;

    @include screen-lg {
      display: flex;
    }
  }

  &__actions {
    display: none;
    grid-area: actions;

    @include screen-md {
      display: flex;
      gap: 12px;
      justify-content: space-between;
      align-items: center;
      font-size: $text-2xs;
    }
  }

  &__desktop-menu {
    display: none;
    grid-area: desktop-menu;

    @include screen-lg {
      display: flex;
    }
  }

  &__mobile-menu {
    position: absolute;
    top: 49px;
    left: -200%;
    transition: left 0.3s;

    @include screen-md {
      top: 57px;
    }

    @include screen-lg {
      display: none;
    }

    &--open {
      left: 0;
    }
  }
}

.collapsed {
  width: 1px;
  opacity: 0;
}
</style>
