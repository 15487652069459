<script lang="ts" setup>
const { contacts } = storeToRefs(useSiteStore());

const isShopSupportModal = ref(false);
</script>

<template>
  <div class="contacts-block">
    <button
      class="font-bold"
      name="open-support-modal"
      type="button"
      @click="isShopSupportModal = true"
    >
      {{ contacts.phone }}
    </button>

    <a
      :href="contacts.tgLink"
      class="flex justify-center items-center gap-2 text-xs"
      rel="nofollow noopener"
      target="_blank"
    >
      <SvgoDialog
        :font-controlled="false"
        class="h-3"
        filled
      />
      Задать вопрос в Telegram
    </a>

    <ContactsModal v-model:is-show-support-modal="isShopSupportModal" />
  </div>
</template>

<style lang="scss" scoped>
.contacts-block {
  // display will come from the parent component
  flex-direction: column;
  gap: 8px;
  align-items: center;
}
</style>
