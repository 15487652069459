<script lang="ts" setup>
const isShowSupportModal = ref(false);
</script>

<template>
  <button
    class="phone-button"
    name="open-support-modal"
    type="button"
    @click="isShowSupportModal = true"
  >
    <SvgoPhone
      :font-controlled="false"
      class="size-6 sm:size-7 md:size-8"
      filled
    />

    <ContactsModal v-model:is-show-support-modal="isShowSupportModal" />
  </button>
</template>

<style lang="scss" scoped>
.phone-button {
  flex-shrink: 0;
  overflow: hidden;
  width: 24px;
  color: $hell-black-60;
  transition: all 0.3s;

  &:hover {
    color: $hell-black-80;
  }

  @include screen-md {
    width: 32px;
  }
}
</style>
