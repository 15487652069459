<script lang="ts" setup>
import { SEARCH_RESULT_TYPES } from '~/constants/searchResultTypes';

defineProps({
  url: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  image: {
    type: Object,
  },
  price: {
    type: Number,
    default: 0,
  },
  type: {
    type: String,
    default: '',
  },
});

const searchStore = useSearchStore();
</script>

<template>
  <AppLink
    :to="url"
    class="search-results-list-item"
    @click="searchStore.setSearchHistory({ name, url })"
  >
    <template v-if="image">
      <picture v-if="type === SEARCH_RESULT_TYPES.brand">
        <source
          :srcset="image.conversions_webp.thumb_80"
          type="image/webp"
        />

        <source
          :srcset="image.conversions.thumb_80"
          type="image/jpeg"
        />

        <img
          :src="image.url"
          alt="image"
          class="search-results-list-item__image"
        />
      </picture>

      <picture v-else>
        <source
          :srcset="image.conversions_webp?.thumb_url_180"
          type="image/webp"
        />

        <source
          :srcset="image.conversions?.thumb_url_65"
          type="image/jpeg"
        />

        <img
          :src="image.url"
          alt="image"
          class="search-results-list-item__image"
        />
      </picture>
    </template>

    <SvgoNoPhotoSmall
      v-else
      :font-controlled="false"
      class="w-10 h-10"
      filled
    />

    <div class="flex flex-col gap-1 py-2">
      <div v-html="highlightTextOccurrence(name, searchStore.searchQuery)" />

      <div
        v-if="type === SEARCH_RESULT_TYPES.offer"
        class="flex gap-3"
      >
        <span class="font-bold">
          {{ `${price.toLocaleString('ru-RU')} ₽` }}
        </span>
      </div>
    </div>
  </AppLink>
</template>

<style lang="scss" scoped>
.search-results-list-item {
  display: grid;
  grid-template-columns: 48px 1fr;
  gap: 16px;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid $hell-black-20;
  font-size: 14px;

  &:last-of-type {
    padding-bottom: 0;
  }

  &__image {
    justify-self: center;
    object-fit: contain;
    min-height: 40px;
    max-height: 62px;
    border-radius: 8px;
  }
}
</style>
