<script lang="ts" setup>
import type { Ref } from 'vue';
import type { DesktopMenuItem } from '~/types/SiteSettings';

const desktopMenu: Ref<DesktopMenuItem[]> = ref([]);

const siteStore = useSiteStore();
desktopMenu.value = await siteStore.getDesktopMenu();

const hoveredItem: Ref<DesktopMenuItem | null> = ref(null);

const isDropDownOpen = computed(() => hoveredItem.value?.children?.length);

function closeDropDown() {
  hoveredItem.value = null;
}
</script>

<template>
  <nav @mouseleave="closeDropDown">
    <div class="menu-wrapper">
      <div class="menu">
        <AppLink
          v-for="item in desktopMenu"
          :key="item.slug"
          :to="makeUrlRelative(item.url)"
          class="menu__item"
          @mouseover="hoveredItem = item"
        >
          <img
            v-if="item.svg_url"
            :alt="item.name"
            :src="item.svg_url"
            class="size-5"
          />
          <div
            v-else-if="item.svg_icon"
            class="size-5"
            v-html="item.svg_icon"
          />
          {{ item.name }}
        </AppLink>
      </div>
    </div>

    <Transition name="dropdown">
      <div
        v-if="isDropDownOpen"
        class="menu__dropdown-wrapper"
      >
        <div class="menu__dropdown">
          <AppLink
            v-for="child in hoveredItem?.children"
            :key="child.slug"
            :to="makeUrlRelative(child.url)"
            class="flex items-center gap-3 break-inside-avoid pb-2"
          >
            <img
              v-if="child.svg_url"
              :alt="child.name"
              :src="child.svg_url"
              class="size-5"
            />
            <div
              v-else-if="child.svg_icon"
              class="size-5"
              v-html="child.svg_icon"
            />
            {{ child.name }}
          </AppLink>
        </div>
      </div>
    </Transition>
  </nav>
</template>

<style lang="scss" scoped>
.menu-wrapper {
  position: relative;
  width: 100%;
}

.menu {
  display: flex;
  justify-content: space-between;
  overflow-y: scroll;
  font-size: 14px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  &__item {
    display: flex;
    flex-shrink: 0;
    gap: 8px;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid $hell-black-20;
    }

    &:first-of-type {
      padding-left: 0;
    }
  }

  &__dropdown-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    background-color: $white;
    box-shadow: 0 8px 12px rgba($black, 0.08);
  }

  &__dropdown {
    row-gap: 12px;
    max-width: $site-width;
    height: fit-content;
    margin: 0 auto;
    padding: 12px 24px;
    font-size: 14px;
    column-count: 4;
    column-gap: 24px;
  }
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: opacity 0.3s;
}

.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
}
</style>
