<script setup lang="ts">
const siteStore = useSiteStore();

function hideAboveHeaderBlock() {
  siteStore.hideAboveHeaderBlock();
}
</script>

<template>
  <div class="w-full h-7 py-1 text-center text-xs sm:text-sm bg-hell-black-10">
    <AppLink :to="siteStore.aboveHeaderBlock.url">
      {{ siteStore.aboveHeaderBlock.text }}
    </AppLink>

    <button
      type="button"
      @click="hideAboveHeaderBlock"
    >
      <SvgoCross class="absolute top-1 right-1 text-xl" />
    </button>
  </div>
</template>
