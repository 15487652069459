<script lang="ts" setup>
import { debounce } from '@antfu/utils';
import type { SearchByQueryResponse } from '~/types/NewTypes/Search';

const isSearchOpen = defineModel<boolean>('isSearchOpen', { default: false });

const searchStore = useSearchStore();
const siteStore = useSiteStore();

const { searchQuery } = storeToRefs(searchStore);
const isLoading = ref(false);

const placeholderText = computed(() => {
  return isSearchOpen.value ? 'Поиск по каталогу' : 'Поиск';
});

const initialSearchResultsState = { offers: [], brands: [], categories: [], seoCatalogPages: [] };

const results = ref<SearchByQueryResponse>(initialSearchResultsState);

const { executeRecaptcha } = useCaptcha();

async function fetchData() {
  if (searchQuery.value.trim().length >= 3) {
    isLoading.value = true;

    const { token } = await executeRecaptcha('search');

    results.value = await searchStore.searchByQuery(token);
    isLoading.value = false;
  } else {
    results.value = initialSearchResultsState;
  }
}

const history = ref([]);

function getSearchHistory() {
  const browserHistory = localStorage.getItem('search-history');

  if (browserHistory) {
    history.value = JSON.parse(browserHistory);
  }
}

const debouncedSearch = debounce(500, fetchData);

watch(searchQuery, async (newQuery) => {
  if (!newQuery.length) {
    getSearchHistory();
  } else {
    debouncedSearch();
  }
});

function openSearch() {
  siteStore.toggleBodyScrollLock(true);
  siteStore.isMobileMenuOpen = false;
  isSearchOpen.value = true;
}

function closeAndResetSearch() {
  siteStore.toggleBodyScrollLock(false);
  isSearchOpen.value = false;
  searchStore.searchQuery = '';
  results.value = initialSearchResultsState;
}

function goToSearchPage() {
  const search = searchStore.searchQuery;

  closeAndResetSearch();

  return navigateTo({
    path: '/search',
    query: { 'filter[search]': search },
  });
}
</script>

<template>
  <div
    :class="{ 'search-block--show': isSearchOpen }"
    class="search-block"
    role="search"
    @keydown.esc="closeAndResetSearch"
  >
    <button
      :class="{ 'search-block__overlay--show': isSearchOpen }"
      class="search-block__overlay"
      @click="closeAndResetSearch"
    />

    <div
      :class="{ 'search-block__content--show': isSearchOpen }"
      class="search-block__content"
    >
      <div class="flex items-center justify-end gap-3 w-full">
        <InputField
          v-model="searchQuery"
          :class="{ 'search-block__input--show': isSearchOpen }"
          :is-loading="isLoading"
          :is-show-clear-btn="isSearchOpen"
          :placeholder="placeholderText"
          append-button-icon="Search"
          class="search-block__input"
          name="search-in-catalog"
          @close="closeAndResetSearch"
          @focus="openSearch"
          @submit="goToSearchPage"
        />
      </div>

      <FoldableBlock
        v-model="isSearchOpen"
        class="w-full"
      >
        <SearchResults
          :results
          @close-search="closeAndResetSearch"
          @go-to-search-page="goToSearchPage"
        />
      </FoldableBlock>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-block {
  width: 100%;
  max-width: 100vw;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#30485b, 0.2);
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.3s,
      visibility 0.3s;

    &--show {
      opacity: 1;
      visibility: visible;
      cursor: pointer;
    }

    &:hover,
    &:focus {
      outline: none;
    }
  }

  &__content {
    position: relative;
    z-index: 110;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-end;
    overflow: hidden;
    padding: 6px;
    border-radius: 12px;
    background-color: $white;
    transition: all 0.3s ease-in;

    @include screen-sm {
      align-items: stretch;
    }

    &--show {
      box-shadow: 0 0 15px rgba($black, 0.1);
    }
  }

  &__input {
    width: 120px;
    transition: all 0.3s;

    @include screen-md {
      width: 100%;
    }

    &--show {
      width: 100%;
    }
  }
}
</style>
