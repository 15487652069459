<script lang="ts" setup>
import dayjs from 'dayjs';
import checkScrollbarWidth from '~/utils/scrollbarWidth';

dayjs.locale('ru');
checkScrollbarWidth();

const siteStore = useSiteStore();
const authStore = useAuthStore();
const cartStore = useCartStore();
const catalogStore = useCatalogStore();
const profileStore = useProfileStore();
const route = useRoute();

authStore.isLogged = false;

await profileStore.getUserInfo();

authStore.isLogged = profileStore.userDetails !== null;

await Promise.any([
  authStore.getAccessRights(),
  siteStore.getSiteSettings(),
  siteStore.getRecommendationBlocks(),
  cartStore.getCart(),
  catalogStore.getFavorites(),
]);

const { isShowAboveHeaderBlock } = storeToRefs(siteStore);
const { accessRights } = storeToRefs(authStore);

const { width } = useBreakpoints();

const isShowHeader = ref(true);
const lastScrollPosition = ref(0);
const scrollOffset = ref(50);

watch(
  () => width.value,
  () => {
    if (width.value >= 1024) {
      isShowHeader.value = true;
    }
  },
);

function onScroll() {
  if (
    window.scrollY < 0 ||
    window.innerWidth >= 1024 ||
    siteStore.isMobileMenuOpen ||
    Math.abs(window.scrollY - lastScrollPosition.value) < scrollOffset.value
  ) {
    return;
  }
  isShowHeader.value = window.scrollY < lastScrollPosition.value;
  lastScrollPosition.value = window.scrollY;
}

function copyListener(event: ClipboardEvent) {
  const range = document.getSelection()?.getRangeAt(0),
    rangeContents = range?.cloneContents(),
    pageLink = `Узнайте больше о трюковых самокатах на ${document.location.href}`,
    helper = document.createElement('div');

  if (rangeContents) helper.appendChild(rangeContents);

  if (helper.innerText.length > 100) {
    event.clipboardData?.setData('text/plain', `${helper.innerText}\n${pageLink}`);
    event.clipboardData?.setData('text/html', `${helper.innerHTML}<br><br>${pageLink}`);
    event.preventDefault();
  }
}

const { isReesEnabled, isRoistatEnabled, isVkPixelEnabled, isYandexMetrikaEnabled, yandexMetrikaId } =
  useRuntimeConfig().public;

useHead({
  script: [
    isReesEnabled === 'true'
      ? {
          type: 'text/javascript',
          innerHTML: `
            (function(r){
              window.r46=window.r46||function(){(window.r46.q=window.r46.q||[]).push(arguments)};
              var c='https://cdn.rees46.ru',v='/v3.js',s={link:[{href:c,rel:'dns-prefetch'},{href:c,rel:'preconnect'},{href:c+v,rel:'preload',as:'script'}],script:[{src:c+v,async:''}]};
              Object.keys(s).forEach(function(c){s[c].forEach(function(d){var e=document.createElement(c),a;for(a in d)e.setAttribute(a,d[a]);document.head.appendChild(e)})});
            })();

            window.r46('init', '3bf8f0bf4cce5fd40e8f5a706851e3');
          `,
          tagPosition: 'head',
        }
      : {},
    isRoistatEnabled === 'true'
      ? {
          innerHTML: `
            (function(w, d, s, h, id) {
              w.roistatProjectId = id; w.roistatHost = h;
              var p = d.location.protocol == 'https:' ? 'https://' : 'http://';
              var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? '/dist/module.js' : '/api/site/1.0/'+id+'/init?referrer='+encodeURIComponent(d.location.href);
              var js = d.createElement(s); js.charset='UTF-8'; js.async = 1; js.src = p+h+u;
              var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
            })(window, document, 'script', 'cloud.roistat.com', '866adbbb3b248c4ca9af453e81e9be35');
          `,
          tagPosition: 'bodyClose',
        }
      : {},
    isVkPixelEnabled === 'true'
      ? {
          type: 'text/javascript',
          innerHTML: `
            var _tmr = window._tmr || (window._tmr = []);
            _tmr.push({ id: '3511953', type: 'pageView', start: new Date().getTime() });
            (function (d, w, id) {
              if (d.getElementById(id)) return;
              var ts = d.createElement('script');
              ts.type = 'text/javascript';
              ts.async = true;
              ts.id = id;
              ts.src = 'https://top-fwz1.mail.ru/js/code.js';
              var f = function () {
                var s = d.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(ts, s);
              };
              if (w.opera == '[object Opera]') {
                d.addEventListener('DOMContentLoaded', f, false);
              } else {
                f();
              }
            })(document, window, 'tmr-code');
          `,
          tagPosition: 'head',
        }
      : {},
    isYandexMetrikaEnabled === 'true'
      ? {
          type: 'text/javascript',
          innerHTML: `
            (function(m,e,t,r,i,k,a) {
              m[i]=m[i] || function(){(m[i].a=m[i].a || []).push(arguments)};
              m[i].l=1*new Date();
              k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)
            })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

            ym(${yandexMetrikaId}, 'init', {
              id:${yandexMetrikaId},
              ecommerce: true,
              webvisor: true,
              trackHash: true,
            });
          `,
        }
      : {},
  ],
  noscript: [
    isVkPixelEnabled === 'true'
      ? {
          innerHTML: `
            <div>
              <img
                alt="Top.Mail.Ru"
                src="https://top-fwz1.mail.ru/counter?id=3511953;js=na"
                style="position: absolute; left: -9999px"
              />
            </div>
          `,
        }
      : {},
    isYandexMetrikaEnabled === 'true'
      ? {
          innerHTML: `
            <noscript>
              <div>
                <img src="https://mc.yandex.ru/watch/${yandexMetrikaId}" style="position:absolute; left:-9999px;" alt="" />
              </div>
            </noscript>
          `,
        }
      : {},
  ],
});

onMounted(() => {
  lastScrollPosition.value = window.scrollY;
  document.addEventListener('scroll', onScroll);
  document.addEventListener('copy', copyListener);
});

onBeforeUnmount(() => {
  document.removeEventListener('scroll', onScroll);
  document.removeEventListener('copy', copyListener);
});
</script>

<template>
  <div
    :class="{ 'with-top-bar': isShowAboveHeaderBlock }"
    class="default-layout"
  >
    <AdminPanelBlock v-if="accessRights?.info_panel" />

    <TheHeader
      :class="{ 'is-hidden': !isShowHeader }"
      class="header"
    />

    <main class="page__wrapper">
      <div
        :class="{ 'no-padding': String(route.name).includes('profile') }"
        class="page__content"
      >
        <slot />
      </div>
    </main>

    <TheFooter />

    <MobileBottomNavigation />
  </div>
</template>

<style lang="scss" scoped>
.default-layout {
  $top-bar: 28px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  padding-top: calc(76px - $top-bar);

  &.with-top-bar {
    padding-top: 76px;
  }

  @include screen-md {
    padding-top: calc(85px - $top-bar);

    &.with-top-bar {
      padding-top: 85px;
    }
  }

  @include screen-lg {
    padding-top: calc(138px - $top-bar);

    &.with-top-bar {
      padding-top: 138px;
    }
  }
}

.header {
  transition: transform 210ms linear;
  transform: translateY(0);
}

.header.is-hidden {
  transform: translateY(-100%);
}

.page {
  &__wrapper {
    @include inner-wrapper;

    flex-grow: 1;
    background-color: $hell-black-5;
  }

  &__content {
    width: 100%;
    padding: 24px 0 48px;

    &.no-padding {
      padding-top: 0;
    }
  }
}
</style>
